import React, {useEffect, useState} from 'react';

import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {Link} from 'gatsby';

import facebook from '../images/facebook.svg';

const styles = {
  menuWrapper: {display: 'flex', justifyContent: 'space-between', margin: '0 auto'},
  menu: mobile => {
    return {
      display: 'flex',
      justifyContent: 'space-around',
      fontSize: mobile ? '1.5rem' : '12px',
      paddingTop: mobile ? '.5rem': 0,
      fontFamily: 'Open Sans, Helvetica, Arial',
      textDecoration: 'none',
      margin: '0 0.7rem',
      color: '#888888',
      textTransform: 'uppercase',
      '&:hover': {color: '#f33f3f', textDecoration: 'none'},
    }
  },
  facebook: {color: 'green', width: 96, height: 33, margin: '.5rem 1rem'},
  facebookBackground: {backgroundImage: `url(${facebook})`, backgroundRepeat: 'no-repeat', width: '100%', height: '100%'},
  nav: {
    padding: "auto"
  },
  // navbar: {
  //   margin: "1.5rem auto",
  // },
  navLink: {
    padding: 0,
  },
  navbar: {
    width: '100%'
  }
};


function MainMenu() {
  const isBrowser = () => typeof window !== "undefined";
  const mediaMatch = isBrowser() && window.matchMedia('only screen and (max-width: 768px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const menuItems = [
    {key: 1, title: "Strona główna", url: "/"},
    // {key: 2, title: "Przewóz osób", url: "/busy-anglia"},
    {key: 3, title: "Paczki", url: "/paczki"},
    {key: 4, title: "Przeprowadzki", url: "/cennik/przeprowadzki"},
    // {key: 5, title: "Zwierzęta", url: "/przewoz-zwierzat"},
    // {key: 6, title: "Laweta", url: "/cennik/transport-na-lawecie"},
    {key: 7, title: "Cennik", url: "/cennik"},
    {key: 8, title: "Kontakt", url: "/kontakt"},
  ];
  return (
    <div css={styles.menuWrapper}>
      <Navbar expand="lg" css={styles.navbar}>
        <Navbar.Toggle aria-controls="main-menu"/>
        <Navbar.Collapse id="main-menu" classNmae="d-flex">
          <Nav className="h6" css={styles.nav}>
            {menuItems.map(menuItem => <Nav.Link css={styles.navLink} key={menuItem.key} className="custom-nav-link">
              <Link css={styles.menu(matches)} activeClassName={"test"} to={menuItem.url}> {menuItem.title}</Link>
            </Nav.Link>)}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div css={styles.facebook}><a href="https://www.facebook.com/przewozypasazerskie.uktrans"><div css={styles.facebookBackground}></div></a></div>
    </div>
  );
}

export default MainMenu;