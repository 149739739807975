import React from 'react'
import PropTypes from 'prop-types'
import {StaticQuery, graphql} from 'gatsby'

import Grid from '@material-ui/core/Grid';

// import WebFont from 'webfontloader';

import Header from './header'
import Footer from './footer'
import './layout.css'
import 'bootstrap/dist/css/bootstrap.min.css';


const classes = {
  root: {
    margin: `0px auto`,
    maxWidth: 960,
    paddingTop: 0,
    boxShadow: "0px 0px 5px 2px #CCC"
  },
  grid: {
    minHeight: "75vh",
    padding: "0px 1.0085rem 145.rem",
    margin: "0 auto"
  }
};

// WebFont.load({
//   google: {
//     families: ['Open Sans:300,400,600,700', 'sans-serif']
//   }
// });

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          css={classes.root}
        >
          <Header siteTitle={data.site.siteMetadata.title}/>
          <Grid css={classes.grid} >
            {children}
          </Grid>
          <Footer />
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};


export default Layout
