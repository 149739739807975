import React from 'react';


const styles = {
  footer: {
    background: "#333",
    borderTop: "6px solid #F33F3F",
    borderBottom: "1px solid #111",
    padding: "15px 10px",
  },
  text: {
    color: "#EEE",
    fontSize: "14px",
    textTransform: "uppercase",
    paddingBottom: "8px",
    margin: "10px 0px 15px",
  },
  border: { borderBottom: "1px solid #222"},
  greyText: {
    color: "#AAA",
    fontSize: "12px"
  },
  copyright: {
    color: "#EEE",
    background: "#222",
    borderTop: "1px solid #444",
    padding: "15px 0px",
    textAlign: 'center'
  }
};


export default () => {
  return <footer>
    <div className="d-flex justify-content-center" css={styles.footer}>
      <div className="col col-sm-auto"/>
      <div className="col-12 col-sm-auto text-center">
        <div css={{...styles.text, ...styles.border}}>
          KONTAKT Z NAMI:
        </div>
        <div css={styles.greyText}>UK-TRANS</div>
        <p><div css={styles.greyText}>Pracujemy od poniedziałku do niedzieli w godzinach 9.00-21.00</div></p>
        <div css={styles.greyText}>
          <p>mail: <a css={{color: "#EEE", ':hover':{color: '#f71208'}}} href="mailto:kontakt.uktrans@gmail.com">kontakt.uktrans@gmail.com</a></p>
          <p>
            <div>Polska +48 513 900 083</div>
            <div>Anglia +44 759 818 6916</div>
          </p>
        </div>
      </div>
      <div className="col col-sm-auto"/>
    </div>
    <div className="col" css={styles.copyright}>Copyright © UK-TRANS 2023</div>
  </footer>
}