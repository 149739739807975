import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import Menu from './menu';


import header from '../images/header.jpg';


const styles = {
  root: {
    background: `white`,
    marginBottom: `0`,
  },
  menuWrapper: {
    color: `white`,
    textDecoration: `none`,
    lineWidth: 'auto',
  },
  img: {
    marginBottom: "0"
  },
  menuItem: { margin: "0"}
};

const Header = () => (
  <div
    css={styles.root}
  >
    <div
      css={styles.menuWrapper}
    >
      <h1 css={styles.menuItem}>
        <Link
          to="/"
        >
          <img src={header} alt="" css={styles.img} />
        </Link>
        <Menu />
      </h1>
    </div>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default withStyles(styles)(Header)
